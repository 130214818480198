import React from 'react'
import Portfolios from '../components/portfolios'
import PortfoliosJSON from "../content/portfolios.json"
import InnovationsJSON from "../content/innovations.json"
import AIChart from "../components/ai-chart"

export default function ai() {
    const content = PortfoliosJSON.portfolios[1]
    const innovation = InnovationsJSON.innovations[1]
    const subcontent = {
        title: innovation.title,
        header: InnovationsJSON.header,
        portSubheader: InnovationsJSON.subheader,
        description: innovation.description,
        logo: innovation.logo,
        shortName: content.shortName
    }
    return (
        <Portfolios content={content} subcontent={subcontent} chart={<AIChart />} />
    )
}
