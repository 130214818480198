import React, {useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AIChartData from "../content/mcalpine_lock_and_dam.json"

const configChart = ({ data }) => {
    return {
        chart: {
            type: 'column',
            zoomType: 'x',
        },
        title: {
            text: data.name,
        },
        xAxis: {
            categories: data.date,
        },
        yAxis: {
            title: {
                text: 'Clearance (ft)'
            },
        },
        tooltip: {
            shared: true,
            crosshairs: true,
            borderWidth: 0,
            valueSuffix: ' ft'
        },
        plotOptions: {
            series: {
                pointWidth: 35
            }
        },
        series: [{
            showInLegend: false,
            data: data.vals,
            color: 'steelblue',
            negativeColor: "darkred",
            name: "Clearance"
        }],
        credits: false
    }
}

export default function AIChart() {
    const [chartData] = useState({
        data: AIChartData,
    })

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={configChart(chartData)}
            />
            <div className="mt-2 text-center d-flex justify-content-around">
                <h6 style={{color: 'black', letterSpacing: 0}}>Horiz Clearance: {AIChartData.horiz} ft,</h6>
                <h6 style={{color: 'black', letterSpacing: 0}}>Low Steel: {AIChartData.low} ft,</h6>
                <h6 style={{color: 'black', letterSpacing: 0}}>Gage: {AIChartData.gage}</h6>
            </div>
        </>
    )
}
